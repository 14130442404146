<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Rooms" subtitle="Manage the rooms in which people can arrange events." :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no rooms found.">

		<app-content-note name="schedule.types.creating">
		
			By creating rooms you allow attendees to submit events to them and, if applicable, even book specific spaces within (e.g. table) for their time slot. You do not need to have rooms, or spaces within them, for attendees to be able to submit events.
		
		</app-content-note>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column align="center" text="Public" />
				<app-table-column border="left" align="center" text="Badges" />
				<app-table-column border="right" align="center" text="Spaces" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-icon align="center" :theme="item.status === 1 ? 'green' : 'red'" :icon="item.status === 1 ? 'tick' : 'cross'" />
					<app-table-cell-text border="left" align="center" :text="item.count.access" v-if="item.status === $constants.schedule.room.status.private" />
					<app-table-cell-text border="left" align="center" text="-" v-if="item.status === $constants.schedule.room.status.public" />
					<app-table-cell-text border="right" align="center" :text="item.count.spaces" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 80px 80px 80px 24px',
			endpoint: 'convention/schedule/rooms',
			live: 'convention/schedule/rooms'
		}

	}

}

</script>

<style scoped>

</style>